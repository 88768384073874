
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authToken')?true:false); //verifica se o token está nos cookies
  const [token, setToken] = useState(localStorage.getItem('authToken')); // obtem o token dos cookies, caso exista
  //console.log(token);

  const login = (userToken) => {
    setToken(userToken);
    setIsAuthenticated(true);
    localStorage.setItem('authToken', userToken); 
  };

  const logout = () => { 
    setToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('authToken');
    
    
  };

  const removeAcess = () => { //não utilizado
    toast(
      'Até !',{
        icon:'👋'
      }

    );

   



  }

  return (
    <AuthContext.Provider value={{ isAuthenticated,login, logout,removeAcess ,token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
