import React from 'react';
import { AreaChart,  XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer } from 'recharts';



const AreaGraph = ({ title, xAxisLabel, xAxisData, yAxisLabel, yAxisDataComandas, yAxisDataNotasEmitidas }) => {

  const data = xAxisData.map((x, index) => ({
    xValue: x,
    Comandas: yAxisDataComandas[index],
    NotasEmitidas:yAxisDataNotasEmitidas[index]
    
  }));
  console.log(yAxisDataComandas);
  console.log(yAxisDataNotasEmitidas);
  return (
    <div className='graph-container'>
      <h3 >{title}</h3>
      <ResponsiveContainer width={'100%'} height={300}>
        <AreaChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <defs>
            <linearGradient id="Comandas" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#A82743" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#A82743" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="NotasEmitidas" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="xValue" label={{ value: xAxisLabel, position: 'insideBottomLeft', offset: -10 }} />
          <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideRight', offset: 60 }} />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="Comandas" stackId="2" stroke="#A82743" fillOpacity={0.7} fill="url(#Comandas)" dot={true} />
          <Area type="monotone" dataKey="NotasEmitidas" stackId="1" stroke="#8884d8" fillOpacity={0.7} fill="url(#NotasEmitidas)" dot={true} />
         
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaGraph;
