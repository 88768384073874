import React from 'react';

const DataCard = ({ title, value }) => {
  // Verifica se o título começa com '$' e se o valor é um número para aplicar formatação de moeda
  const formattedValue =
    title.startsWith('$') && typeof value === 'number'
      ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
      : value;

  return (
    <div className="card">
      <div className='card_col'>
      <h2><b>{title}</b></h2>
     <p>{formattedValue}</p>
      </div>
    </div>
  );
};

export default DataCard;
