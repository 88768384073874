
import { Toaster,toast } from "react-hot-toast";

const API_LOGIN = process.env.REACT_APP_LOGIN_API

const ApiLogin = {
    post: async(body= {}) => {
        const response = await fetch(`${API_LOGIN}/public/sessions/restaurants`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body: JSON.stringify(body)


        });

    if(!response.ok){
        const errorData = await response.json();
        toast.error('Erro ao realizar o login: ' + (errorData.error || 'Erro desconhecido'));
        throw new Error(errorData.error||'Login failed');
    }

    const data = await response.json();

    const token = data.token;

    return token;

    }




};

export default ApiLogin;
