import {toast, Toaster} from 'react-hot-toast';
import React, { useEffect, useState, useRef } from 'react';
import { DefaultSelect, FullCalendar, InformationDiv } from 'ui-kit-takeat';
import Dashboard from './Dashboard';
import logoImg from '../assets/Union.png'
import {Button} from 'ui-kit-takeat';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import ApiData from '../ApiData';

const MetricsForm = () => {

  
  const [selectedDate, setSelectedDate] = useState({ start: null, end: null });
  const [loading,setLoading] = useState();
  const[selectedRestaurant, setSelectedRestaurant] = useState([]);
  const[selectedRestaurantId, setSelectedID] = useState(null);
  const{logout} = useAuth();
  const navigate = useNavigate();
  const handleLogout= () =>{
    logout();
    navigate('/login');

  }


  const handleDateSelected = async  (date) => {

      
        date.end.setDate(date.end.getDate()); // Esses métodos juntos automaticamente incrementam + 1 dia em date.end ===> date.end = '2024-10-01 23:59' -> '2024-10-02 00:00:00'
       
        /*  Compara e verifica se a data selecionada é maior que 3 meses*/
        const yearsDiff = date.end.getFullYear() - date.start.getFullYear(); 
        const monthsDiff = date.end.getMonth() - date.start.getMonth();
        const totalMonthsDiff = yearsDiff * 12 + monthsDiff;
        if(totalMonthsDiff>3){
            toast.error(
              "Escolha um intervalo de até 3 meses"
            );

        }else{
        
        const start = date.start.toISOString().slice(0,10); //Aqui eu converto a data selecionada para string e pego somente as datas sem as horas => '2024-10-01'
        const end = date.end.toISOString().slice(0,10);
        setSelectedDate({start,end});
        }
 
    
  };



  useEffect(()=>{

    const fetchRestaurants = async() => {
      setLoading(true);

      toast(
        'Por favor Aguarde',{
          icon:'⌛'
        }
      )

      try {

          
        const restaurants_fantasy_names = await ApiData.post({},'restaurant/list');
        

        const restaurantsOptions = restaurants_fantasy_names.restaurants.map((restaraunt)=> ({
          label: restaraunt.fantasy_name,
          value: restaraunt.id  //associa o ID como valor da seleçao 

        

         }));


      
        
        
      setSelectedRestaurant(restaurantsOptions);


      }catch(error){
        throw new Error('Erro ao buscar restaurantes:', error);
        
      }
      setLoading(false);

    };

    
    fetchRestaurants();





  }, [] );


  const handleSelectedChange = (selectedOption) =>{
    setSelectedID(selectedOption.value);
    
  }

    return (
      
      <div className="background">

        <header className='header'>
         
          <img src={logoImg} alt='Logo' className='header-logo'/>
         
          
          <Button title= "Sair" onClick ={handleLogout} style={{position:'absolute',right:20}} inverted buttonColor='#FFFFFF'/> 
          
        </header>

        <div className="div_cabecalho2" >
         
        <table>
          <tbody>
            <tr>
              
              <td>
              <DefaultSelect options={[{ value: null, label: "Métricas Gerais Takeat" }, ...selectedRestaurant]} onChange={handleSelectedChange} isSearchable placeholder='Selecione um restaurante' />
               
                <Toaster position='top-center'/>
              </td>
             
            </tr> 
            
            <tr>
              <td>
              <FullCalendar 
                  calendarColor="#FF2C3A"
                  hideFields={['today','this_year']}
                  onDateSelected={handleDateSelected}
                  value={{
                    end: new Date(),
                    start: new Date()
                  }}
                
                isLoading = {loading}/>  {/* animação de "carregando" do calendário  */}
              </td>
            </tr>     
          </tbody>
        </table>
        

       <InformationDiv

 text="Valores relacionados a data de criação das entidades"
 title="Aviso!!"
 type="warning"

/>

   


      


</div>
        <div className="dashboard">
          {selectedDate.start && selectedDate.end ? (
            <Dashboard start={`${selectedDate.start} 03:00:00.000Z`} 
                       end={`${selectedDate.end} 02:59:59.5959Z`} 
                       restaurant_id={selectedRestaurantId}
                       setLoading = {setLoading}/> //animação de carragando do calendário 
          ) : (
            <p>Por favor, selecione um restaraunte e um intervalo de datas.</p>
          )}
        </div>
        <div className='exit_button'>


</div>


</div>
      
    );
  
  }
    export default MetricsForm;
     